<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="4">
          <h3>قم بأرفاق صورة الكتاب الفارغ هنا :</h3>
          <v-file-input
            v-model="file"
            label="أرفق صورة الكتاب الفارغ"
            prepend-icon="mdi-file-image"
            accept="image/*"
            color="primary"
            @change="onFileChanged"
            outlined
            hide-details=""
            filled
            class="mt-3"
          ></v-file-input>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="8">
          <v-img :src="preview" height="700px" contain class="mt-1"></v-img>
        </v-col>
        <v-col cols="4">
          <v-card>
            <v-card-title>
              قم بسحب الحقول المطلوبة لمكانها المناسب في الكتاب
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-card outlined v-for="field in fields" :key="field.name">
                <v-card-title>
                  {{ field.label }}
                </v-card-title>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      file: null,
      preview: null,
      fields: [
        {
          name: "number",
          label: "العدد",
          type: "number",
          value: "",
        },
        {
          name: "date",
          label: "تاريخ الكتاب",
          type: "text",
          value: "",
        },
        {
          name: "incomingNumber",
          label: "رقم الوارد",
          type: "text",
          value: "",
        },
        {
          name: "date2",
          label: "تاريخ الوارد",
          type: "text",
          value: "",
        },
      ],
    };
  },
  methods: {
    onFileChanged() {
      var reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.preview = reader.result;
      };
    },
  },
};
</script>
